<script>
import TaskList from "components/suporte/components/task/List"
import {list, dashboardSuporte} from "@/domain/tarefas/services"
import {UFakeLoader} from "uloc-vue"

export default {
  name: 'SuporteDashboard',
  mixins: [],
  components: {
    TaskList,
    UFakeLoader
  },
  data () {
    return {
      loadingStats: true,
      table: {
        serverData: [],
        selected: [],
        loading: false,
        filters: {
          id: null,
          busca: null,
          internalStatus: ['opened'],
          assigned: this.$uloc.auth.session.user.person
        },
        serverPagination: {
          page: 1,
          rowsNumber: 0,
          rowsPerPage: 50
        }
      },
      stats: {
        me: 0,
        requestedByMe: 0,
        projects: 0,
        actionsNeeded: 0,
        tasksDue: 0,
        criticalAlerts: 0
      }
    }
  },
  computed: {
  },
  mounted() {
    this.load()
  },
  methods: {
    request({pagination, filter}) {
      const extraFilters = []
      this.table.filters.id && extraFilters.push(`&id=${this.table.filters.id}`)
      this.table.filters.assigned && extraFilters.push(`&assigned=${this.$uloc.auth.session.user.person}`)
      extraFilters.push(`&internalStatus=${this.table.filters.internalStatus.join(',')}`)

      this.table.loading = true
      let filtros = encodeURI(`&sortBy=${pagination.sortBy || 'queuePosition'}&descending=${pagination.sortBy ? (pagination.descending ? 'true' : 'false') : true}${extraFilters.join('')}`)

      list(pagination.rowsPerPage, pagination.page, filtros, this.isSuporte)
          .then(({data}) => {
            this.table.serverPagination = pagination
            this.table.serverPagination.rowsNumber = data.total
            this.table.serverData = data.result
            this.table.loading = false
          })
          .catch(error => {
            this.alertApiError(error)
            this.loading = false
          })
    },
    loadDashboard () {
      this.loadingStats = true
      dashboardSuporte()
          .then(response => {
            this.stats.me = response.data.me
            this.stats.requestedByMe = response.data.requestedByMe
            this.stats.tasksDue = response.data.tasksDue
            this.loadingStats = false
          })
          .catch(error => {
            this.loadingStats = false
            this.alertApiError(error)
          })
    },
    load() {
      this.loadDashboard()
      this.request({
        pagination: this.table.serverPagination,
        filter: this.table.filters.busca
      })
    }
  },
  meta: {
    title: 'Suporte',
    name: 'Suporte'
  }
}
</script>

<template>
  <div class="scontent sdash">
    <div class="dash-btns">
      <a class="dash-btn">
        <div class="img"><img src="../assets/images/checked.png"></div>
        <div class="label">Suas Tarefas</div>
        <div class="value" v-if="!loadingStats">{{ stats.me }}</div>
        <div class="value" v-else><u-fake-loader class="m-t" width="40px" height="10px" /></div>
      </a>
<!--      <div class="dash-btn">
        <div class="img"><img src="../assets/images/clock.png"></div>
        <div class="label">Carga Horária</div>
        <div class="value blue">0h</div>
      </div>-->
      <div class="dash-btn">
        <div class="img"><img src="../assets/images/doc-check.png"></div>
        <div class="label">Suas Solicitações</div>
        <div class="value" v-if="!loadingStats">{{ stats.requestedByMe }}</div>
        <div class="value" v-else><u-fake-loader class="m-t" width="40px" height="10px" /></div>
      </div>
      <div class="dash-btn">
        <div class="img"><img src="../assets/images/folder.png"></div>
        <div class="label">Seus Projetos</div>
        <div class="value" v-if="!loadingStats">{{ stats.projects }}</div>
        <div class="value" v-else><u-fake-loader class="m-t" width="40px" height="10px" /></div>
      </div>
      <div class="dash-btn">
        <div class="img"><img src="../assets/images/task-list.png"></div>
        <div class="label">Ações Necessárias</div>
        <div class="value red" v-if="!loadingStats">{{ stats.actionsNeeded }}</div>
        <div class="value" v-else><u-fake-loader class="m-t" width="40px" height="10px" /></div>
      </div>
      <router-link tag="a" :to="{name: 'suporte.tarefas.fila', params: {fila: 'due'}}" class="dash-btn">
        <div class="img"><img src="../assets/images/computer-alert.png"></div>
        <div class="label">Tarefas Atrasadas</div>
        <div class="value red" v-if="!loadingStats">{{ stats.tasksDue }}</div>
        <div class="value" v-else><u-fake-loader class="m-t" width="40px" height="10px" /></div>
      </router-link>
      <div class="dash-btn">
        <div class="img"><img src="../assets/images/task-alert.png"></div>
        <div class="label">Alertas Críticos</div>
        <div class="value red" v-if="!loadingStats">{{ stats.criticalAlerts }}</div>
        <div class="value" v-else><u-fake-loader class="m-t" width="40px" height="10px" /></div>
      </div>
    </div>

    <div class="sbox">
      <div class="stitle cursor-pointer" @click="load">Sua fila de tarefas</div>
      <task-list :data="table.serverData" :request="request" :options="table" :option-column="false" colorize />
    </div>
  </div>
</template>
